<script setup lang='ts'>
import QRCode from 'qrcode'

const props = withDefaults(defineProps<{
  to?: string
}>(), {
  to: '',
})
const url = computed(() => {
  return props.to.match(/^http/) ? props.to : `${window.location.origin}${props.to}`
})
const qrcode = ref('')
watch(url, (val) => {
  val && generateCode(val)
}, { immediate: true })
function generateCode(url: string) {
  QRCode.toDataURL(url)
    .then((url: string) => {
      qrcode.value = url
    }).catch((err: any) => {
      console.error(`generateCode error:${err}`)
    })
}
</script>

<template>
  <NPopover v-if="url && qrcode" raw @click.stop>
    <template #trigger>
      <i v-bind="$attrs" class="i-mdi:qrcode" />
    </template>
    <div class="flex flex-col items-center bg-white">
      <img width="100" height="100" :src="qrcode">
      <div class="text-center text-3 pb-2 text-slate-7">
        手机扫码查看
      </div>
    </div>
  </NPopover>
</template>
